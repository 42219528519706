import React, { useEffect } from "react";
import tire from "../assets/images/tire.png";
import { makeStyles } from "@material-ui/styles";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles({
  indicator: {
    position: "fixed",
    backgroundColor: "transparent",
    width: "55px",
    right: "90px",
    bottom: "17px",
    zIndex: "99999",
  },
});

const playAnimation = () => {
  // Selecting the stylesheet from the DOM
  let styleSheet = document.styleSheets[0];
  // preparing the basic animation
  let keyframes = `
      @keyframes rotating {
          from  { transform: rotate(0deg) }
          to    { transform: rotate(360deg) }
    }`;
  // Mounting the animation to the selected stylesheet
  styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
};

// Setting the animation properties in a css object
const perpetualRotation = {
  width: "100%",
  animationName: "rotating",
  animationTimingFunction: "linear",
  animationDuration: "0.67s",
  animationDelay: "0s",
  animationIterationCount: "infinite",
  animationDirection: "normal",
  animationFillMode: "forwards",
};

const LoadingIndicator = (props) => {
  useEffect(() => {
    // play the animation when the component mounts
    playAnimation();
  });

  const classes = useStyles();

  return (
    // Show the loading indicator if the loadCount is more than 0
    props.loadCount > 0 ? (
      <Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <div className={classes.indicator}>
          <img
            src={tire}
            style={perpetualRotation}
            alt="Something on the page is currently loading"
          />
        </div>
      </Slide>
    ) : (
      <React.Fragment />
    )
  );
};

export default LoadingIndicator;
