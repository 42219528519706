import React from "react";
import { Auth } from "aws-amplify";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import Toast from "../common/Toast";
import AppTitle from "../common/AppTitle";
import BtnLink from "../common/BtnLink";
import Translate from "../common/Translate";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AppContext } from "../AppProvider";
import { theme } from "../theme";

const useStyles = (theme) => ({
  root: {
    display: "block",
    "& input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active": {
      boxShadow: "0 0 0 30px #242a37 inset !important",
      "-webkit-box-shadow": "0 0 0 30px #242a37 inset !important",
      "-webkit-text-fill-color": "#fff !important",
    },
    height: "100vh",
    "& .MuiInput-underline:before": {
      borderBottomColor: "rgba(255,255,255,0.3)",
    },
    '& label, & label[data-shrink = "true"]': {
      color: "#7F8FA4",
      fontSize: "16px",
      fontFamily: "MuseoSans500",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    background: "#242A37",
    color: "#fff",
    padding: theme.spacing(5.5, 8, 2.5),
    maxWidth: "500px",
    margin: "3% auto",
  },
  title: {
    marginBottom: theme.spacing(2.75),
  },
  subTitle: {
    paddingBottom: theme.spacing(3),
  },
  forgotPass: {
    color: "#fff",
    fontSize: "16px",
    fontFamily: "MuseoSans300",
    background: "none!important",
    border: "none",
    padding: "0!important",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  bgStyle: {
    display: "grid",
    margin: "auto",
    background: "#181C28 !important",
    height: "100%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    color: "#fff",
    "& input": {
      color: "#fff",
    },
  },
  submit: {
    margin: theme.spacing(4, 0, 4.5),
    fontSize: "24px",
    disabled: {
      color: "#fff",
    },
  },
  confirmedMsg: {
    padding: "2px 0 23px 0",
    fontSize: "20px",
  },
  submitDisabled: {
    margin: theme.spacing(3, 0, 2),
    fontSize: "24px",
    color: "#FFF",
  },
  here: {
    margin: "0 !important",
    minWidth: "auto !important",
  },
  checkbox: {
    margin: theme.spacing(3, 0, 2),
  },
  agree: {
    margin: "29px 0 2px",
  },
  error: {
    color: "#fff",
    fontSize: "12px",
  },
  toolTip: {
    fontSize: "13px",
  },
});

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      code: "",
      new_password: "",
      correctUser: false,
      error: "",
    };
  }

  return() {
    this.props.onStateChange("signIn", this.state.username);
  }
  confirm = () => {
    if (this.state.correctUser) {
      Auth.forgotPasswordSubmit(
        this.state.username,
        this.state.code.trim(),
        this.state.new_password
      )
        .then((data) => {
          this.props.onStateChange("signIn");
        })
        .catch((err) => {
          this.setState({ error: err.message || err.log });
          this.setState({ loading: false });
        });
    } else {
      Auth.forgotPassword(this.state.username)
        .then((data) => {
          this.setState({ correctUser: true });
        })
        .catch((err) => {
          this.setState({ error: err.message || err.log });
          this.setState({ loading: false });
        });
    }
  };

  resend() {
    Auth.resendSignUp(this.state.username)
      .then(() => {
        this.setState({ resend: true });
      })
      .catch((err) => this.setState({ error: err.message || err.log }));
  }

  goToSignIn = () => {
    this.props.onStateChange("signIn", {});
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  reset = () => {
    this.setState({
      username: "",
      code: "",
      new_password: "",
      correctUser: false,
      error: "",
    });
  };

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.authState !== prevProps.authState &&
      this.props.authState === "forgotPassword"
    ) {
      this.reset();
    }
  }

  render() {
    const { classes } = this.props;
    const { error } = this.state;
    return (
      <AppContext.Consumer>
        {(context) =>
          this.props.authState === "forgotPassword" && (
            <Grid container component="main" className={classes.root}>
              <Toast>
                {error && error.includes("INVALID_LICENSE")
                  ? "Invalid Mountain Course licence"
                  : error}
              </Toast>
              <CssBaseline />
              <Grid item xs={12} sm={12} md={12} className={classes.bgStyle}>
                <div style={{ display: "grid", margin: "auto 35%" }}>
                  {/*to do : wrap the App into MuiThemeProvider*/}
                  <MuiThemeProvider
                    theme = {theme}
                  >
                    <AppTitle big className={classes.apptitle} />
                    <Grid>
                      <Paper className={classes.paper}>
                        <div>
                          <Typography
                            variant="h1"
                            className={classes.title}
                            style={{ fontSize: "2.5rem" }}
                          >
                            <Translate i18nKey="reset_password" />
                          </Typography>
                          <form id="confirmForm" className={classes.form}>
                            <Grid container spacing={1} alignItems="flex-end">
                              <Grid item md={11}>
                                <TextField
                                  fullWidth
                                  className={classes.form}
                                  margin="normal"
                                  id="username"
                                  key="username"
                                  name="username"
                                  type="text"
                                  value={this.state.username || ""}
                                  onChange={(e) => this.handleChange(e)}
                                  label={<Translate i18nKey="username" />}
                                  autoFocus
                                />
                              </Grid>
                            </Grid>
                            {this.state.correctUser && (
                              <div>
                                <Grid
                                  container
                                  spacing={1}
                                  alignItems="flex-end"
                                >
                                  <Grid item md={11}>
                                    <TextField
                                      fullWidth
                                      className={classes.form}
                                      label={<Translate i18nKey="code" />}
                                      margin="normal"
                                      id="code"
                                      key="code"
                                      name="code"
                                      type="text"
                                      value={this.state.code || ""}
                                      onChange={(e) => this.handleChange(e)}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  spacing={1}
                                  alignItems="flex-end"
                                >
                                  <Grid item md={11}>
                                    <TextField
                                      fullWidth
                                      className={classes.form}
                                      label={
                                        <Translate i18nKey="new_password" />
                                      }
                                      margin="normal"
                                      id="new_password"
                                      key="new_password"
                                      name="new_password"
                                      type="password"
                                      value={this.state.new_password || ""}
                                      onChange={(e) => this.handleChange(e)}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                            <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              className={classes.submit}
                              onClick={this.confirm}
                              //disabled={!(values.checked && isValid)}
                            >
                              {this.state.loading ? (
                                <CircularProgress
                                  style={{ color: "#FFFFFF" }}
                                />
                              ) : (
                                <Translate i18nKey="send" />
                              )}
                            </Button>
                          </form>
                          <Grid container style={{ width: "100%" }}>
                            <Grid item xs>
                              <BtnLink
                                click={this.goToSignIn}
                                label={<Translate i18nKey="back_to_sign_in" />}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </Paper>
                    </Grid>
                  </MuiThemeProvider>
                </div>
              </Grid>
            </Grid>
          )
        }
      </AppContext.Consumer>
    );
  }
}
export default withStyles(useStyles)(ResetPassword);
