export const config = {
  s3: {
    region: process.env.REACT_APP_REGION || "S3_UPLOADS_BUCKET_REGION",
    bucket: "S3_UPLOADS_BUCKET_NAME",
  },
  apiGateway: {
    name: "IOM",
    region: process.env.REACT_APP_REGION || "eu-west-1",
    endpoint:
      process.env.REACT_APP_API ||
      "https://jy0g1nli18.execute-api.eu-west-1.amazonaws.com/api",
  },
  cognito: {
    frontOfficeUserPool: {
      region: process.env.REACT_APP_FO_REGION || "eu-west-1",
      userPoolId:
        process.env.REACT_APP_FO_USER_POOL_ID || "eu-west-1_aBVx9OsGp",
      userPoolWebClientId:
        process.env.REACT_APP_FO_CLIENT_ID || "3ge293mfmujtp1ef0i8ptk9lib"
    },
    identityPoolId:
      process.env.REACT_APP_IDENTITY_POOL_ID ||
      "eu-west-1:9c47fc36-31b7-49f0-9a0f-5d8f8746153d"
  },
  fontSize: {
    small: process.env.REACT_APP_FONT_SIZE_SM || "small",
    medium: process.env.REACT_APP_FONT_SIZE_MD || "medium",
    large: process.env.REACT_APP_FONT_SIZE_LG || "large",
    xlarge: process.env.REACT_APP_FONT_SIZE_XL || "x-large",
  },
  websocket: {
    endpoint:
      process.env.REACT_APP_WEBSOCKET_API ||
      "u3xmnng3pd.execute-api.eu-west-1.amazonaws.com",
    env: process.REACT_APP_WEBSOCKET_ENV || "api",
    region: process.REACT_APP_WEBSOCKET_REGION || "eu-west-1",
  },
  timingWebsocket: {
    endpoint:
      process.env.REACT_APP_TIMING_WEBSOCKET_API ||
      "hqkq6e2htj.execute-api.eu-west-1.amazonaws.com",
    env: process.REACT_APP_TIMING_WEBSOCKET_ENV || "api",
    region: process.REACT_APP_TIMING_WEBSOCKET_REGION || "eu-west-1",
  },
  quickSight: {
    region: process.env.REACT_APP_REGION || "eu-west-1",
    url:
      process.env.REACT_APP_QUICKSIGHT_URL ||
      "https://eu-west-1.quicksight.aws.amazon.com/sn/start?directory_alias=rcs-staging",
  },
};
