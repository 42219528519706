import { useContext } from "react";
import { AppContext } from "../AppProvider";
import langueEN from "../i18n/en";
import langueFR from "../i18n/fr";
import { getCurrentLanguage } from "./language";

export default function Translate(props){
  const context = useContext(AppContext);
  const lang = context ? context.state.language : getCurrentLanguage();
  const label = props.i18nKey ? props.i18nKey : props;
  switch (lang) {
    case "fr":
      return langueFR[label] ? langueFR[label] : label;
    case "en":
      return langueEN[label] ? langueEN[label] : label;
    default:
      return label;
  }
};
