import React from 'react';



var style = {
    backgroundColor: "rgba(36, 42, 55, 0.9)",
   // textAlign: "left",
    padding: "17px",
    position: "fixed",
    bottom: "0",
    left:"0",
    height: "40px",
    width: "100%",
    color:"white",
    zIndex:9998
   // marginleft:"30px",
}
var c={
    color: "rgba(255, 254, 254)",
    marginTop:"-5px",


}
var D={
    color: "rgba(255, 254, 254)",
    marginTop:"-20px",
    textAlign:"right",
    maginRight:"0px"
    
}
var A={
  color: "rgba(255, 254, 254)",
  textDecoration: "none"
}
function Footer() {
   return (
     <div>
        
         <div style={style} >
           <div style={c}>
             ©2020 Crown Copyright</div> 
           
           <div style={D}>
              <a style={A} href="https://www.iomttraces.com/legal/cookie-policy">Cookie Policy</a> / <a style={A} href=" https://www.iomttraces.com/legal/privacy-policy" >Privacy Policy</a> / <a style={A} href="https://www.iomttraces.com/contact">Contact Form </a>
           </div>

        </div>
     </div>
    
    )
}
export default Footer;