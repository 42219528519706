import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
}));

export default function NavBar(props) {
  // const { context } = props;
  const classes = useStyles();
  /*const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };*/

  const getCurrentValue = () => {
    return 0;
  };

  return (
    <div className={classes.root}>
      <Tabs
        color="transparent"
        value={getCurrentValue()}
        // onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="action tabs example"
      >
        <Tab label="RCS Home" component={Link} to="/"></Tab>

        {/* {context.accessible("ct_gps") && (
          <Tab label="CT GPS" component={Link} to="/gps/ct"></Tab>
        )} */}
      </Tabs>
    </div>
  );
}
