import React from "react";
import Translate from "./Translate";

const ErrorToast = (props) => {
  if (!props.error) {
    return null;
  }
  if (props.error.code) {
    switch (props.error) {
      case "InvalidParameterException":
        if (props.error["message"].includes("confirmationCode")) {
          return (
            <div className="toast">
              <span>
                <Translate i18nKey="invalid_confirmation_code" />
              </span>
            </div>
          );
        } else if (props.error["message"].includes("regular expression")) {
          return (
            <div className="toast">
              <span>
                <Translate i18nKey="invalid_passed_username" />
              </span>
            </div>
          );
        } else {
          return (
            <div className="toast">
              <span>{props.error["message"]}</span>
            </div>
          );
        }

      case "UserLambdaValidationException":
        if (props.error["message"].includes("Invalid username")) {
          return (
            <div className="toast">
              <span>
                <Translate i18nKey="invalid_username" />
              </span>
            </div>
          );
        } else {
          return (
            <div className="toast">
              <span>{props.error["message"]}</span>
            </div>
          );
        }
      case "ValidationError":
        if (props.error["message"].includes("password")) {
          return (
            <div className="toast">
              <span>
                <Translate i18nKey="invalid_password" />
              </span>
            </div>
          );
        } else {
          return (
            <div className="toast">
              <span>{props.error["message"]}</span>
            </div>
          );
        }

      default:
        if (props.error["message"].includes("CUSTOM_AUTH")) {
          return (
            <div className="toast">
              <span>
                <Translate i18nKey="empty_password" />
              </span>
            </div>
          );
        } else {
          return (
            <div className="toast">
              <span>{props.error.message}</span>
            </div>
          );
        }
    }
  } else if (props.error.name) {
    switch (props.error.name) {
      case "AuthError":
        if (props.error["log"].includes("username should either be a string")) {
          return (
            <div className="toast">
              <span>
                <Translate i18nKey="empty_username" />
              </span>
            </div>
          );
        } else {
          return (
            <div className="toast">
              <span>{props.error["log"]}</span>
            </div>
          );
        }
      default:
        return (
          <div className="toast">
            <span>{props.error.log}</span>
          </div>
        );
    }
  } else {
    return (
      <div className="toast">
        <span>
          {props.error
            ? props.error
            : "An error has occured. Please check your information"}
        </span>
      </div>
    );
  }
};
export default ErrorToast;
