import { I18n } from "aws-amplify";

const key = "language";
const defaultLanguage = "en";

export function initLanguage() {
  if (!localStorage.language) {
    setCurrentLanguage(defaultLanguage);
  } else {
    I18n.setLanguage(localStorage.getItem(key));
  }
}

export function setCurrentLanguage(language) {
  localStorage.setItem(key, language);
  I18n.setLanguage(language);
}

export function getCurrentLanguage() {
  return localStorage.getItem(key);
}
