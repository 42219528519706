import { withRouter, Switch, Route } from "react-router";
import { lazy, Suspense } from "react";
import React from "react";
import LoadingIndicator from "./LoadingIndicator";

const Profile = withRouter(
  lazy(() => import(/* webpackChunkName: "profile" */ "../user/Profile"))
);

const Users = withRouter(
  lazy(() => import(/* webpackChunkName: "profile" */ "../users/Users"))
);

export default function Routes(props) {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Switch>
        <Route path="/profile" exact component={Profile} />
        <Route path="/users" exact component={Users} />
      </Switch>
    </Suspense>
  );
}
