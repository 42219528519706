import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../theme";

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "MuseoSans300",
    background: "none!important",
    border: "none",
    padding: "0!important",
    cursor: "pointer",
    margin: "0 5px",
    fontSize: theme.typography.button.fontSize,
    "&:hover": {
      textDecoration: "underline"
    }
  }
}));

export default function BtnLink(props) {
  const classes = useStyles();
  return (
    <button
      variant="body2"
      className={classes.root}
      target={props.target}
      onClick={props.click}
      style={{
        color: props.color ? props.color : theme.palette.primary.main
      }}
    >
      {props.label}
    </button>
  );
}
