import React from "react";
import { Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import Close from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "ProximaBold",
    textAlign: "center",
    color: "#e1e3ea"
  },
  big: {
    fontSize: 24
  }
}));

const AppTitle = props => {
  const classes = useStyles();
  return (
    <div>
      <div style={{ display: 'inline-block', padding: 20 }}>
        <Typography
          id={props.id}
          style={props.style}
          className={clsx(classes.root, props.className, {
            [classes.big]: props.big
          })}
        >
          IOM - MOUNTAIN COURSE
    </Typography>
      </div>
      <div style={{ display: 'inline-block' }}>
        <IconButton
          onClick={props.action}
          style={{ padding: 10 }}
        >
          <Close style={{ color: '#878ea0' }} fontSize='small' />
        </IconButton>
      </div>
    </div>
  );
};

export default AppTitle;
