import React, { forwardRef, useEffect } from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {
  MenuItem,
  Divider,
  // Badge,
  Menu,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import { Auth } from "aws-amplify";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import MainMenu from "./MainMenu";
import AppTitle from "./AppTitle";
import { AppContext } from "../AppProvider";
import { config } from "../config";
import Translate from "./Translate";
// import BtnLink from "./BtnLink";
import NavBar from "./NavBar";
import Footer from "./Footer";

const drawerWidth = 280;
const appBarHeight = "auto";
const menuButtonColor = "#fff";
const appBarBackground = "#242A37";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appTitle: {
    fontSize: ".95rem",
  },
  appBar: {
    backgroundColor: "#242A37",
    height: appBarHeight,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  noShift: {
    marginLeft: 20,
  },
  menuButton: {
    padding: 0,
    borderRadius: "none",
    width: "100%",
    height: "100%",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 9999,
  },
  drawerPaper: {
    backgroundColor: "#242A37",
    width: drawerWidth,
  },
  drawerHeader: {
    backgroundColor: "#242A37",
    color: "#fff",
    padding: 0,
  },
  button: {
    textDecoration: "none",
    color: "unset",
  },

  content: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },

  gpsContent: {
    marginTop: 0,
    width: "100%",
    // height: "100%",
    padding: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },

  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },

  langSwitcher: {
    padding: "9px 0 9px 11px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  langMenu: {
    "&:hover": {
      backgroundColor: "unset",
      cursor: "initial",
    },
  },
  fontMenu: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  toolbar: {
    height: appBarHeight,
    // display: "grid",
    // gridTemplateColumns: "1fr 5rem 1fr 10rem",
    background: appBarBackground,
  },
  container: {
    marginTop: theme.spacing(4),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(10),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  mainMenu: {
    backgroundColor: "#242A37",
    color: "#878ea0",
  },
}));

const NavMenuItem = forwardRef((props, ref) => {
  return (
    <MenuItem
      component={NavLink}
      to={{ pathname: props.route, search: window.location.search }}
      onClick={props.onClick}
      classes={props.classes}
    >
      {props.children}
    </MenuItem>
  );
});

export default function Layout(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState();
  const openUserMenu = Boolean(anchorEl);
  const [customFontSize, setCustomFontSize] = React.useState(null);
  const [openLeftDrawer, setOpenLeftDrawer] = React.useState(false);

  useEffect(() => {
    Auth.currentUserCredentials().catch((error) => {
      Auth.signOut();
    });
  }, []);

  useEffect(() => {
    Auth.currentUserPoolUser().then((res) => {
      setState(res.attributes);
    });
  }, []);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeFontSize = (event, sizeLabel) => {
    const size = config.fontSize[sizeLabel];
    setCustomFontSize(size);
  };

  return (
    <AppContext.Consumer>
      {(context) => (
        <div className={classes.root}>
          <CssBaseline />
          {customFontSize && (
            <style jsx="true">{`
              *
                :not(#app-title-open):not(#app-title-close):not(#burger):not(#burger-closed):not(.mainMenu):not(h1):not(.icon-ntt) {
                font-size: ${customFontSize};
              }
            `}</style>
          )}
          <AppBar
            className={`${classes.appBar} ${
              openLeftDrawer ? classes.appBarShift : classes.noShift
            }`}
          >
            <Toolbar className={classes.toolbar} disableGutters variant="dense">
              {!openLeftDrawer && (
                <div
                  style={{
                    display: "inline-block",
                    width: 80,
                    paddingLeft: 10,
                  }}
                >
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setOpenLeftDrawer(true)}
                  >
                    <MenuIcon />
                  </IconButton>
                </div>
              )}

              <NavBar context={context} />
              <div style={{ margin: "auto auto auto 15px" }}></div>
              <div style={{ paddingRight: 20 }}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircleIcon size="small" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={openUserMenu}
                  onClose={handleClose}
                  className={classes.menu}
                >
                  <NavMenuItem route="/profile" onClick={handleClose}>
                    <div>
                      <div style={{ fontWeight: "bold" }}>
                        {state && state.given_name} {state && state.family_name}
                      </div>
                      <div>{state && state.email}</div>
                    </div>
                  </NavMenuItem>
                  <Divider />

                  <NavMenuItem route="/services" onClick={handleClose}>
                    <Translate i18nKey="my_services" />
                  </NavMenuItem>
                  <MenuItem
                    aria-label="change language"
                    aria-controls="menu-lang"
                    aria-haspopup="true"
                    color="inherit"
                    className={classes.langMenu}
                  >
                    <Translate i18nKey="change_text_size" />
                  </MenuItem>
                  <MenuItem className={classes.fontMenu}>
                    <ButtonGroup
                      color="secondary"
                      aria-label="outlined primary button group"
                    >
                      <Button
                        onClick={(event) => changeFontSize(event, "small")}
                      >
                        SM
                      </Button>
                      <Button
                        onClick={(event) => changeFontSize(event, "medium")}
                      >
                        MD
                      </Button>
                      <Button
                        onClick={(event) => changeFontSize(event, "large")}
                      >
                        LG
                      </Button>
                      <Button
                        onClick={(event) => changeFontSize(event, "xlarge")}
                      >
                        XL
                      </Button>
                    </ButtonGroup>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => Auth.signOut()}>
                    <Translate i18nKey="sign_out" />
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
          {openLeftDrawer && (
            <Drawer
              className={classes.drawer}
              variant="permanent"
              anchor="left"
              open={openLeftDrawer}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <Toolbar
                variant="dense"
                disableGutters
                className={classes.drawerHeader}
              >
                <AppTitle
                  id="app-title-close"
                  className={classes.appTitle}
                  style={{ color: menuButtonColor }}
                  action={() => setOpenLeftDrawer(false)}
                />
              </Toolbar>
              <MainMenu className={classes.mainMenu} context={context} />
            </Drawer>
          )}
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: true /*open*/,
            })}
            style={{ width: "100%" }}
          >
            {props.children}

            <Footer />
          </main>
        </div>
      )}
    </AppContext.Consumer>
  );
}
