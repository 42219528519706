import React from "react";

const Toast = props => {
  if (!props.children) {
    return null;
  }

  return (
    <div className="toast">
      <span>{props.children}</span>
    </div>
  );
};

export default Toast;
