import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Translate from "./Translate";

import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  icons: {
    fill: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
  },
  text: {
    color: theme.palette.text.secondary,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    paddingTop: 1,
    paddingBottom: 1,
    backgroundColor: theme.palette.primary.main + 15,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& *": {
        color: "#fff",
      },
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "& *": {
      color: "#fff",
    },
  },
}));

function CustomChildItem({ styling, nested, icon, label, route }) {
  const c = useStyles();
  return (
    <ListItem
      style={styling}
      button
      component={NavLink}
      to={{ pathname: route, search: window.location.search }}
      className={`${window.location.pathname === route && c.active} ${
        nested && c.nested
      }`}
    >
      <ListItemIcon className={c.icons}>{icon}</ListItemIcon>
      <ListItemText className={c.text} primary={label} />
    </ListItem>
  );
}

var style = {
  zIndex: 9999,
};

export default function MainMenu({ context, className }) {
  useEffect(() => {
    Auth.currentUserCredentials().catch((error) => {
      Auth.signOut();
    });
  }, []);

  return (
    <List className={className} style={style}>
      {/* These boys are not nested */}

      <CustomChildItem
        label={<Translate i18nKey="user_management" />}
        icon={<GroupIcon className="icon-ntt" />}
        route="/users"
      />
    </List>
  );
}
