import React, { Component } from "react";
import { setCurrentLanguage, getCurrentLanguage } from "./common/language";

// Create new context
export const AppContext = React.createContext();

// Then create a Provider Component
export class AppProvider extends Component {
  state = {
    language: getCurrentLanguage(),
  };

  changeLanguage = (lang) => {
    this.setState(
      { language: lang }, //updater
      () => {
        setCurrentLanguage(this.state.language);
      } // callback
    );
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          state: this.state,
          changeLanguage: this.changeLanguage,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
